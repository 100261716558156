<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 5px; text-align: center">
      <el-row>
        <el-col :span="8">请选择上传文件</el-col>
        <el-col :span="8">
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            accept=".docx"
            :headers="uploadHeaders"
            :on-success="successUploadFile"
            :limit="1"
          >
          </el-upload>
        </el-col>
      </el-row>
     
    </el-card>
  </div>
</template>

<script>
import * as API from "../../utils/request";

export default {
  data() {
    return {
      uploadHeaders: {},
      actionUrl: "",
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },

  // 方法定义
  methods: {
    init() {
      let token = localStorage.getItem("access-token");
      this.uploadHeaders.Authorization = token;
      this.actionUrl = API.getUrl() + "/sysjob/upload";
    },

    successUploadFile: function (res) {
      let that=this;
      console.log("upload file " + JSON.stringify(res));
      if (res.status == 1) {
        console.log('success');
        this.$message.success('文件解析成功');
        setTimeout(function(){
          that.$router.go(0);
        },1000*2);
      }
    },
  },
};
</script>

<style scoped>
</style>